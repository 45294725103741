import GenericEntity from "@/assets/Entities/GenericEntity";
import User from "@/assets/Entities/User";

export default class SchoolClass extends GenericEntity{
    _id!: string;
    name!: string;
    students!: number | User[];
    teachers!: number | User[];
    headTeachers!: User[];
    schoolId!: string;

    constructor(name?: string, students?: number) {
        super();
        this.name = name ?? '';
        this.students = students ?? 0;
        this.schoolId = '';
    }
}