
//@ts-nocheck
import {Component, Watch} from 'vue-property-decorator'
import GenericListComponent, {Filter} from "@/components/generic/GenericListComponent.vue";
import {DataTableHeader} from "vuetify";
import SchoolClass from "@/assets/Entities/SchoolClass";
import ClassEditor from "@/components/class/ClassEditor.vue";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";
import Webservice from "@/assets/service/Webservice";

@Component({
  components: {ClassEditor}
})
export default class ClassList extends GenericListComponent<SchoolClass> {
  created() {
    this.loadItems();
    if (this.$store.getters.currentUser.role === UserType.Admin) {
      Webservice.performRequest(RequestType.GET,
          {type: TargetType.SCHOOL},
          {},
          val => {
            this.$store.commit('SET_AVAILABLE_SCHOOLS', val.items);
          })
    }
  }

  headers: DataTableHeader[] = [
    {
      text: this.$t('Class.List.Table.Header1').toString(),
      value: 'name',
      width: '60%'
    },
    {
      text: this.$t('Class.List.Table.Header2').toString(),
      value: 'students',
      width: '30%'
    },
    {
      text: this.$t('Class.List.Table.Header3').toString(),
      value: 'action',
      sortable: false,
      align: 'center',
      width: '10%'
    }];

  get selectedSchoolId() {
    return this.$store.getters.currentUser.role === UserType.Admin ? (this.filterValues['school'] || -1)
        : (this.$store.getters.currentUser.schoolId || this.$store.getters.currentUser.school._id);
  }

  @Watch('items')
  watchItems() {
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].schoolId = this.selectedSchoolId
    }
  }

  get params(): SchoolURLParams {
    return {
      type: TargetType.CLASS,
      schoolId: this.selectedSchoolId
    }
  }

  get filters(): Filter[] {
    if (this.$store.getters.currentUser.role === UserType.Admin) {
      return [{
        title: 'school',
        items: this.$store.getters.availableSchools,
        text: 'name',
        value: '_id'
      }];
    }
    return [];
  }

  checkBoxLabel: string = 'Class.List.InactiveUsers';

  showInactive: boolean = false;

  title: string = "Class.List.Title";
  addButtonTitle: string = "Class.List.AddClass";
  addButtonIcon: string = "mdi-plus";
  elementActionIcon: string = "mdi-pencil";
  dialog: string = "class-editor";
  rowsPerPageText: string = 'Class.RowsPerPage';
  canBeInactive = false;
}
